export const productData = [
    {
        id: "1",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723454425/myb-course/hacking/cloud%20computing%20and%20hacking/ur6lptu2iulc8q8mwtwh.jpg",
        courseName: "Cloud Computing And Hacking ",
        desc: "",
        description: {
            details: "Would you like to learn cloud computing security because this knowledge can be useful for you to advance your ethical hacking career or prepare for any hacking exam?",
            points: "In this course you will learn:",
            key_points: [
                "Cloud computing theory",
                "Cloud vulnerabilities theory",
                "Cloud security controls",
                "Data centers",
                "Cloud solutions",
                "Using Google Drive plus tips and tricks",
                "Using Drive for hacking",
                "and more…"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "2",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723454610/myb-course/hacking/wireless%20network%20hacking/qcjz7zygmvm1y1ow2aaq.jpg",
        courseName: "Wireless Network Hacking",
        desc: "",
        description: {
            details: "Would you like to learn wireless network hacking because this knowledge can be useful for you to advance your ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "wireless network theory",
                "Wifi hacking",
                "encryption and cryptography theory",
                "WiFi Phisher",
                "hacking wireless WPA networks",
                "and much more...",
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "3",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723454825/myb-course/hacking/web-server%20ethical%20hacking/xz02a3ex0lpi0vmphmfn.jpg",
        courseName: "Web Server Ethical Hacking",
        desc: "",
        description: {
            details: "Would you like to learn how to hack web servers in this course for beginners because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Hacking web servers fundamentals",
                "Installing Python 3 on Windows 7 and setting up simple Python HTTP server",
                "Installing XAMPP on Windows 7",
                "Installing Apache and working with simple web server on Kali Linux",
                "Working with web servers on Kali Linux",
                "Installing and playing around with Bee-Box and Kali Linux",
                "Working with Bee-Box on Kali Linux",
                "and much more...",
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "4",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723454963/myb-course/hacking/system%20hacking/zl4oyx8iecosu788dofx.jpg",
        courseName: "System Hacking",
        desc: "",
        description: {
            details: "Would you like to study system hacking as a beginner in this course to advance your ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Fundamentals of system hacking and remote shells",
                "Basic Linux commands and fundamentals of file structure",
                "Fundamentals of processes and nano",
                "Basics of VIM",
                "Linux processes and permissions",
                "Armitage basics with Kali Linux",
                "and much more...",
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "5",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723455458/myb-course/hacking/session%20hijacking/cv0z8aryaaeuheufhfyn.jpg",
        courseName: "System Hijacking",
        desc: "",
        description: {
            details: "Would you like to learn how to perform session hijacking including manipulating users to hijack session IDs with Kali Linux, Arpspoof & Hamster because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Session hijacking fundamentals",
                "Becoming man in the middle with Kali Linux",
                "Sniffing with Bettercap and Kali Linux",
                "Basics of Burp with Kali Linux",
                "Session hijacking with Kali Linux",
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "6",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723455680/myb-course/hacking/hacking%20web%20apps/eqqjjhbwz3j8nolivs1j.jpg",
        courseName: "Hacking Web Apps",
        desc: "",
        description: {
            details: "Would you like to learn how to hack web applications in this course for beginners because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Web application hacking fundamentals",
                "HTML injection with Kali Linux",
                "Basics of burp and requests with Kali Linux",
                "Iframe injection with Kali Linux",
                "Remote commands injection with Kali Linux",
                "PHP arbitrary code injection with Kali Linux",
                "Broken authentication",
                "and much more…"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "7",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723455995/myb-course/hacking/Hacking%20with%20andriod%20ios/tcwobbkgk8v5ufuvpeos.jpg",
        courseName: "Start Hacking With Android, IOS And Windows",
        desc: "",
        description: {
            details: "Would you like to learn how to hack with Android, iOS, and Windows smartphones because this knowledge can be useful for you if you want to secure your own mobile devices?",
            points: "In this course you will learn:",
            key_points: [
                "Mobile devices theory",
                "Android hacking tools",
                "Hacking mobile devices engagement",
                "IOS security controls theory",
                "Android security controls theory",
                "How to protect your Android smartphone",
                "and much more…"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "8",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723459590/myb-course/hacking/ethical%20hacking%20using%20kali%20linux/dqkeveveup4lva2deyjc.jpg",
        courseName: "Ethical Hacking Using Kali Linux",
        desc: "",
        description: {
            details: "Would you like to learn how to hack with Android, iOS, and Windows smartphones because this knowledge can be useful for you if you want to secure your own mobile devices?",
            points: "In this course you will learn:",
            key_points: [
                "Mobile devices theory",
                "Android hacking tools",
                "Hacking mobile devices engagement",
                "IOS security controls theory",
                "Android security controls theory",
                "How to protect your Android smartphone",
                "and much more…"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "9",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723459753/myb-course/hacking/hacking/gjgpv6ocrhi4x5vqxae8.jpg",
        courseName: "Hacking",
        desc: "",
        description: {
            details: "Would you like to learn Internet of Things Hacking (IoT) because this knowledge can be useful for you to advance your ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "IoT theory",
                "IoT hacking tools",
                "Hacking IoT",
                "IoT scenario",
                "Smart devices",
                "AWS basics"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "10",
        image: "https://emeritus.org/in/wp-content/uploads/sites/3/2022/07/ethical-hacking.jpg.optimal.jpg",
        courseName: "Complete Ethical Hacking",
        desc: "",
        description: {
            details: "Would you like to learn how to do ethical hacking and penetration testing because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Introduction to Ethical Hacking",
                "Build your hacking environment!",
                "What is Tor? How can you use it to protect your anonymity online?",
                "ProxyChains for using proxy servers, hiding your ip, and obtaining access.",
                "What is a Virtual Private Network (VPN) and how you can stay anonymous with VPN?",
                "What is a macchanger? How can you use it to change your mac address?",
                "Footprinting with Nmap and external resources.",
                "Attacking wireless networks. Overview of the tools.",
                "Breaking WPA/WPA2 encryption. WiFi hacking and WiFi hacker training.",
                "Signal jamming and denial of service.",
                "SSL strips.",
                "and much more…"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement.Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "5,002",
        price: "449",
        offPrice: "3099"
    },
]

export const itCourses = [
    {
        id: "10",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460041/myb-course/IT/black%20arch%20linux/htxkkjk2wwrd1gunmfrr.jpg",
        courseName: "Black Arch Linux",
        desc: "",
        description: {
            details: "Would you like to learn BlackArch Linux for penetration testers because this knowledge can be useful for you to advance your ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Preparing Kali Linux",
                "Preparing BlackArch Linux",
                "Ethical Hacker information gathering with BlackArch Linux",
                "Windows, Android & Web application attacks"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "11",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460192/myb-course/IT/cyber%20security/qt42tinm9zxcrwpflaki.jpg",
        courseName: "Cyber Security",
        desc: "",
        description: {
            details: "Would you like to learn cryptography for cyber security because this knowledge can be useful for you to advance your ethical hacking career or prepare for an official hacking exam?",
            points: "In this course you will learn:",
            key_points: [
                "Cryptographic theory",
                "Asymmetric encryption",
                "Symmetric encryption",
                "Secure Socket Layer (SSL)",
                "Pretty Good Privacy (PGP)",
                "Advanced Encryption Standard (AES)",
                "Wifi security - WPA2",
                "Wifi security - WPA3",
                "Vulnerabilities - TLS1.3",
                "Hashing algorithms",
                "and more…"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "12",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460358/myb-course/IT/dos%20and%20ddos/bqdb3q8toojnuxzo6yig.jpg",
        courseName: "DOS And DDOS",
        desc: "",
        description: {
            details: "Would you like to learn denial of service or DOS attacks and Distributed Denial of Service or DDOS because this will help you to prepare for any official hacking exam or level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Denial of Service fundamentals",
                "Basics of DoS with Kali Linux",
                "DoS trough tor network with Kali Linux",
                "How to connect Kali Linux in tor network",
                "How to create a script which connects you to tor network with bash and Kali Linux",
                "DoS with LOIC and Kali Linux",
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "13",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460571/myb-course/IT/evading%20ids/wmfqyvcifinmnzbavq0c.jpg",
        courseName: "Evading IDS",
        desc: "",
        description: {
            details: "Would you like to learn the art of evading IDS, firewalls, and honeypots because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Evading fundamentals",
                "Installing snort IDS on Windows 7",
                "How to create snort rules on Windows 7",
                "Snort on Linux",
                "Setting up vulnerable Windows 7 honeypot",
                "Honeypots",
                "Evading firewalls with Nmap and Kali Linux"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "14",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460719/myb-course/IT/matlab/tuqs12jcxcwfjdyajehq.jpg",
        courseName: "MATLAB",
        desc: "For biginner",
        description: {
            details: "Would you like to learn the art of evading IDS, firewalls, and honeypots because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Evading fundamentals",
                "Installing snort IDS on Windows 7",
                "How to create snort rules on Windows 7",
                "Snort on Linux",
                "Setting up vulnerable Windows 7 honeypot",
                "Honeypots",
                "Evading firewalls with Nmap and Kali Linux"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "15",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460832/myb-course/IT/meter%20web%20framework/sgjr9k3skntueqx3e6df.jpg",
        courseName: "Meter Web Frameworks",
        desc: "",
        description: {
            details: "Would you like to learn the art of evading IDS, firewalls, and honeypots because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Evading fundamentals",
                "Installing snort IDS on Windows 7",
                "How to create snort rules on Windows 7",
                "Snort on Linux",
                "Setting up vulnerable Windows 7 honeypot",
                "Honeypots",
                "Evading firewalls with Nmap and Kali Linux"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "16",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723460936/myb-course/IT/wireshark/o7gesemw81fo2ox2uxeb.jpg",
        courseName: "Wireshark",
        desc: "From basic to advance",
        description: {
            details: "Would you like to learn the art of evading IDS, firewalls, and honeypots because this will help you level up your cyber security and ethical hacking career?",
            points: "In this course you will learn:",
            key_points: [
                "Evading fundamentals",
                "Installing snort IDS on Windows 7",
                "How to create snort rules on Windows 7",
                "Snort on Linux",
                "Setting up vulnerable Windows 7 honeypot",
                "Honeypots",
                "Evading firewalls with Nmap and Kali Linux"
            ],
            end_title:"This course was designed for total beginners and there is no particular knowledge requirement. Thank you for learning about the course and we hope you love it!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
]

export const JavaScriptCourse=[
    {
        id:""
    }
]

export const CoursesName = [
    {
        id: "1",
        course: "Hacking",
        learners: "13.13M+ learners"
    },
    {
        id: "2",
        course: "JavaScript",
        learners: "16.16M+ learners"
    },
    {
        id: "3",
        course: "React JS",
        learners: "7M+ learners"
    },
    {
        id: "4",
        course: "Angular",
        learners: "4M+ learners"
    },
    {
        id: "5",
        course: "Java",
        learners: "14.4M+ learners"
    },
    {
        id: "6",
        course: "Android Development",
        learners: "8M+ learners"
    },
    // {
    //     id:"7",
    //     course:"IOS Development",
    //     learners:"4M+ learners"
    // }
]


export const itSubCourses = [
    {
        id: "1",
        course: "It",
        learners: "13.13M+ learners"
    },
    {
        id: "2",
        course: "JavaScript",
        learners: "16.16M+ learners"
    },
    {
        id: "3",
        course: "React JS",
        learners: "7M+ learners"
    },
    {
        id: "4",
        course: "Angular",
        learners: "4M+ learners"
    },
    {
        id: "5",
        course: "Java",
        learners: "14.4M+ learners"
    },
    {
        id: "6",
        course: "Android Development",
        learners: "8M+ learners"
    },
    // {
    //     id:"7",
    //     course:"IOS Development",
    //     learners:"4M+ learners"
    // }
]