import "./styles/Contact.css"

const Contact = () => {


    return (
        <section>
            <div className="conatact about">
                <div className="mt-5 container ">
                    <div className="hero_section mt-5 d-flex justify-content-around align-items-center">

                        <div className="hero-title ">
                            <h1>Connecting people  <br /> with knowledge</h1>
                        </div>
                        <div className="hero_title_background mt-4" style={{ backgroundImage: `url("https://about.udemy.com/wp-content/uploads/2021/07/company.png")` }}>

                        </div>
                    </div>

                </div>
            </div>
            <div className="container mt-5 desc1">
                        <h2 className="text-center" style={{fontWeight:"700",fontSize:"40px"}}>Our origins</h2>
                        <p className="text-center mt-5 pb-4">Whether you want to learn a new skill, train your teams, or share what you know with the world,<br/> you’re in the right place. As a leader in online learning, we’re here to help you achieve your <br/> goals and transform your life.</p>
                        {/* <p className="text-center mt-3 pb-4">Whether you want to learn a new skill, train your teams, or share what you know with the world,<br/> you’re in the right place. As a leader in online learning, we’re here to help you achieve your <br/> goals and transform your life.</p> */}
                </div>
                <div className="contact-container desc1">
                <h2 className="text-center" style={{fontWeight:"700",fontSize:"40px"}}>Contact us</h2>
                <p className="text-center mt-4 pb-4">The quickest way to get in touch with us is by using the contact information below.</p>
                <div className="mt-3 mb-5">
                    <ul className="text-[17px]">
                        <li className="mb-2 font-semibold"><i className="fa-solid fa-phone"></i> - +91 89792 87631</li>
                        <li className="mb-2 font-semibold"><i className="fa-solid fa-phone"></i> - +91 80594 65353</li>
                        <li className="mb-2 font-semibold"><i className="fa-solid fa-envelope"></i> - contact@hminnovace.com</li>
                        <li className="mb-2 font-semibold"><i className="fa-solid fa-location-dot"></i> - First floor UG-21 Bestech City Centre Mall Dharuhera, 123106</li>
                    </ul>
                </div>
                {/* <div className="conatct-card container">
                    <div >
                        <h4>Learners</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Business</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Instructors</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Partners</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Investors</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Press</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Privacy</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                    <div>
                     <h4>Union</h4>
                        <ul className="mt-3">
                            <li>Visit our <Link style={{textDecoration:"none"}}>Help Center</Link></li>
                            <li>How to reach our support team</li>
                        </ul>
                    </div>
                </div> */}
                </div>
                <div className="mt-5 pb-5">
                <h2 className="text-center " style={{fontWeight:"700",fontSize:"40px"}}>Our offices</h2>
                <div className="container office-section mt-5 pb-5">
                    <div>
                        <img src="https://about.udemy.com/wp-content/uploads/2021/07/san-francisco-460x350.jpeg" alt="" />
                        <h5 style={{fontWeight:"700",fontSize:"30px"}}>San Francisco, CA</h5>
                    </div>
                    <div >
                    <img src="https://about.udemy.com/wp-content/uploads/2021/07/denver-460x350.jpeg" alt="" />
                        <h5 style={{fontWeight:"700",fontSize:"30px"}}>Denver, CO</h5>
                    </div>
                    <div>
                    <img src="https://about.udemy.com/wp-content/uploads/2021/07/dublin-1-460x350.jpeg" alt="" />
                        <h5 style={{fontWeight:"700",fontSize:"30px"}}>Dublin, Ireland</h5>
                    </div>
                    <div>
                    <img src="https://about.udemy.com/wp-content/uploads/2021/07/ankara-1-450x350.jpeg" alt="" />
                        <h5 style={{fontWeight:"700",fontSize:"30px"}}>Ankara, Türkiye</h5>
                    </div>
                    <div>
                    <img src="https://about.udemy.com/wp-content/uploads/2023/04/Austin-480x350.png" alt="" />
                        <h5 style={{fontWeight:"700",fontSize:"30px"}}>Austin, TX</h5>
                    </div>
                    <div>
                    <img src="https://about.udemy.com/wp-content/uploads/2023/04/Melbourne-480x350.png" alt="" />
                        <h5 style={{fontWeight:"700",fontSize:"30px"}} >Melbourne, Australia</h5>
                    </div>
                </div>
                </div>
        </section>
    )
}

export default Contact