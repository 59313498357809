export const popularCourse = [
    {
        id: "01",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723453646/myb-course/programing/react/wfhdfxmvnenqj02j0mcd.jpg",
        courseName: "React.js",
        desc: "",
        descriptionData:"Learn React.js in this course for beginners and start to create interactive UIs painlessly.React makes it painless to create interactive UIs. You can design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes. Declarative views make your code more predictable and easier to debug in React.In this course you will learn how to set up the React environment, how to use attributes and styles, how to use JS expressions and events, how to use props with functional components, and much more…",
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "02",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723453845/myb-course/programing/python/ipqiktiovfbthoxqxorp.jpg",
        courseName: "Python",
        desc: "",
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "03",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723453962/myb-course/programing/c/srt4ciq2bilranavzhre.jpg",
        courseName: "C Programming",
        desc: "",
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "04",
        image: "https://res.cloudinary.com/hminnovance/image/upload/v1723454104/myb-course/programing/golang/xgfkcuvcswyvrfwcnooz.jpg",
        courseName: "Golang Programming",
        desc: "",
        description: {
            details: "Would you like to go from a Google Go beginner to a full-fledged Golang programmer? If so, this Google Go Programming course is for you!Golang is one of the best programming languages around today. There are many different employers searching for Golang programmers who can make applications and websites using their Google Go skills. In fact, freelancers who program in Golang often make between $35 to $155 an hour doing what they love!If you're interested in learning a new skill, changing your thinking, and developing open-source applications",
            points: "In this course you will learn:",
            key_points: [
                "Background information on Golang, including how to install and run all the programs you need on your PC, Mac, or Linux computer.",
                "Programming basics that transfer to any languages you want to learn after becoming a Golang developer.",
                "Advanced skills such as creating for loops, expressions, fallthrough statements, and more.",
                "Web development using Golang, as well as different web programming languages, and more!",
            ],
            end_title:"So, get ready to discover the skills you need to make programs using the Golang language! The only thing you need to begin is a dedication to learning an income earning skill and a computer that runs Windows, macOS, or Linux.To get started, use the Buy or Free Preview buttons at the top of the page or scroll down and click the take this course button for lifetime enrollment!"
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "449",
        offPrice: "3099"
    },
    {
        id: "05",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Android_robot.svg/1745px-Android_robot.svg.png",
        courseName: "Master Android App Development",
        desc: "",
        description: {
            details: "Build your career as an android developer today by taking this complete course! Welcome to the Android Development Master course. What is this course about? We can ask ourselves the question, “Who is an Android developer?” The answer seems pretty obvious for most of us, that weird guy who sits hours behind a screen and making applications for our smart phones. That’s partly true, but there is much more this days. Cars are being managed by Android, televisions have Android as their operating system, and Android Things to build any sort of device such as temperature controllers, life changing medical devices, toys and giant spacecrafts. In this course, we are going to teach you how to make Android applications the right way, by managing resources and taking advantage of all opportunities. After learning in this course, you will be able to bring quality jobs that only senior developers get. ",
            key_points: [],
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "3000",
        offPrice: "3099"
    },
    {
        id: "06",
        image: "https://tinyurl.com/bdhj3p8f",
        courseName: "SQLite Databases",
        desc: "",
        description: {
            details: "Are you a Developer looking to learn how to create and manage databases using SQLite? ",
            points: "Here is what you will learn in my complete SQLite Databases Course:",
            key_points: [
                "How to create tables and insert records into them",
                "Update and delete records in a database",
                "SQLite shell commands",
                "Useful functions available in SQLite",
                "Different Datatypes available in SQLite",
                "All of the complexities of Date in SQLite",
                "Constraints on columns",
                "Advanced Queries",
                "Joining tables together",
                "Subqueries",
                "Creating views",
                "Group By and Having clauses",
                "Transactions",
                "Triggers on tables"
            ],
        },
        rating: "4.4",
        enrolled: "4,268",
        price: "1500",
        offPrice: "300"
    },
]